export enum TaskPriorityLevels {
  lowest = "lowest",
  low = "low",
  medium = "medium",
  high = "high",
  highest = "highest"
}

export enum TaskStatuses {
  todo = "todo",
  in_progress = "in_progress",
  done = "done"
}

export enum CommunicationLogType {
  call = "call",
  task = "task",
  email = "email"
}

export enum CallDisposition {
  no_answer = "no_answer",
  vm = "vm",
  made_contact = "made_contact",
  spoke_with_dm = "spoke_with_dm",
  bad_number = "bad_number",
  discovery_call = "discovery_call"
}

export enum CallSessionStatus {
  InProgress = "in_progress",
  Answered = "answered",
  Unreachable = "unreachable"
}

export enum CalleeType {
  Business = "business",
  PersonalInformation = "owner",
  IndividualProfile = "individual_profile",
  BusinessProfile = "business_profile"
}

export enum LogEndpointType {
  CallSession = "call_sessions",
  Task = "tasks"
}

export enum TelephoneStatus {
  Active,
  DoNotContact,
  BadNumber
}

export enum ActivityHubTab {
  Call = "call",
  Task = "task",
  Email = "email"
}
